
import jsPDF from "jspdf";
import QRCode from 'qrcode';
import moment from 'moment';

import checklistOptions from "@/assets/json/checklistOptions.json";

const logo = require("@/assets/images/logo.png");

const docOptions = {
    orientation: "portrait",
    format: 'a4',
    unit: "mm", // 210 x 297
    autoPaging: 'text',
};

const qrCodeHeight = 24;
const qrCodeWidth = 24;
const qrCodeOptions = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 1,
    margin: 1,
}

var page = 0;
var doc = new jsPDF(docOptions);
let pageWidth = doc.internal.pageSize.width;
let pageHeight = doc.internal.pageSize.height;
let generateQR;

function _printNormalAndBold(inputValue, startX, Y) {
    const arrayOfNormalAndBoldText = inputValue.split('**');
    arrayOfNormalAndBoldText.map((text, i) => {
        doc.setFont(undefined, 'bold');
        // every even item is a normal font weight item
        if (i % 2 === 0) {
            doc.setFont(undefined, 'normal');
        }
        doc.text(text, startX, Y);
        startX = startX + doc.getTextWidth(text);
    });
}

function printPage(item, pages) {
    if (page > 0) {
        doc.addPage();
    }
    page++;

    doc.setFontSize(10);
    let img = new Image();
    img.src = logo;

    doc.addImage(img, 'jpeg', 5, 5, 26, 37);
    doc.addImage(generateQR, "image/png", (pageWidth - qrCodeWidth) - 5, 5, qrCodeHeight, qrCodeWidth);

    let Y = 10;
    doc.setFont(undefined, 'bold').setFontSize(14).text(`RELATÓRIO DE OPERAÇÃO (${checklistOptions[item.type]})`, 40, Y).setFontSize(10).setFont(undefined, 'normal');
    Y += 5;
    _printNormalAndBold(`Geração do relatório: **${moment().format("DD/MM/YYYY HH:mm")}**`, 40, Y);
    Y += 10;
    _printNormalAndBold(`Data da operação: **${moment(item.createdAt).format("DD/MM/YYYY HH:mm")}**`, 40, Y);
    _printNormalAndBold(`Código R2PP: **${item.code}**`, 120, Y);


    Y += 5;
    _printNormalAndBold(`Operador: **${item.createdBy}**`, 40, Y);
    if (item.extraCode) {
        _printNormalAndBold(`Romaneio: **${item.extraCode}**`, 120, Y);
    }
    Y += 10;
    if (item.store) {
        if (item.type == "collect-cd") {
            _printNormalAndBold(`Destino: **${item.store}**`, 40, Y);
        } else {
            _printNormalAndBold(`Loja: **${item.store}**`, 40, Y);
        }
    }

    if (item.ordersNumber) {
        _printNormalAndBold(`Pacotes: **${item.packages ? item.packages.length : 0}** Bipado(s), **${item.ordersNumber}** informado(s)`, 120, Y);
    } else {
        _printNormalAndBold(`Pacotes: **${item.packages ? item.packages.length : 0}** Bipado(s)`, 120, Y);
    }
    if (!item.ordersNumberOnFinish) {
        Y += 5;
        _printNormalAndBold(`**BIPAGEM NÃO FINALIZADA PELO USUÁRIO**`, 120, Y);
    }

    _printNormalAndBold(`**Código**`, 12, 55);
    _printNormalAndBold(`**Chave CTE**`, 65, 55);
    if (item.type != "collect") {
        _printNormalAndBold(`**Loja**`, 180, 55);
    }

    _printNormalAndBold(`Local e Data: ___________________       Assinatura: _______________________________ `, 5, pageHeight - 10);
    if (pages > 0) {
        _printNormalAndBold(`Página: ${page} / ${pages}`, pageWidth - 5 - doc.getTextWidth(`Página: ${page} / ${pages}`), pageHeight - 10);
    }

    return doc;
}

export default {
    create: async (item) => {
        const itemsPerPage = 42;

        page = 0;
        doc = new jsPDF(docOptions);

        generateQR = await QRCode.toDataURL(`https://r2pp.com.br/checklists/${item._id}`, qrCodeOptions);

        let pages = 0;
        if (item.packages && item.packages.length > 0) {
            pages = parseInt(item.packages.length / itemsPerPage);
            const remainder = parseInt(item.packages.length % itemsPerPage);
            if (remainder > 0) {
                pages++;
            }
        }
        doc = printPage(item, pages)
        console.log(item)

        let Y = 55;
        if (item.packages && item.packages.length > 0) {
            const maxDigits = item.packages.length.toString().length;

            let itemCounter = 0;
            for (const p of item.packages) {
                itemCounter++;
                if (parseInt(itemCounter % itemsPerPage) == 0) {
                    doc = printPage(item, pages);
                    Y = 55;
                }

                Y += 5;
                _printNormalAndBold(`[${itemCounter.toString().padStart(maxDigits, '0')}]`, 2, Y);
                _printNormalAndBold(`${p.ticket}`, 12, Y);
                _printNormalAndBold(`${p.key}`, 65, Y);
                if (item.type != "collect") {
                    _printNormalAndBold(`${p.store}`, 180, Y);
                }
            }
        } else {
            _printNormalAndBold(`**Nenhum pacote bipado**`, 5, 60);
        }

        if (item.stamps && item.stamps.length > 0) {
            Y += 10;
            _printNormalAndBold(`**Lacre(s):** ${item.stamps.join(", ")}`, 12, Y);
        }

        return doc;
    },
};